import React, {
  useState,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import { navigate } from "gatsby";
import { AppStateType } from "@/reducers";
import LoginForm from "@/components/pages/login/loginForm";
import ResetPasswordForm from "@/components/pages/login/ResertPasswordForm/ResetPasswordForm";
import ConfirmNewPasswordForm from "@/components/pages/login/ConfirmNewPassword/ConfirmNewPasswordForm";
import RegistrationForm from "@/components/pages/registration";

const Login = () => {
  const { authtoken } = useSelector((state: AppStateType) => state.account);

  const [isActiveLoginForm, setActiveLoginForm] = useState<boolean>(true);
  const [isActiveResetPasswordForm, setActiveResetPasswordForm] = useState<boolean>(false);
  const [isActiveConfirmNewForm, setActiveConfirmNewForm] = useState<boolean>(false);
  const [isActiveRegistrationForm, setActiveRegistrationForm] = useState<boolean>(false);
  const [resetTokenLink, setResetTokenLink] = useState<string>('');

  useEffect(() => {
    if (authtoken) {
      navigate("/platform/home/");
    }
  }, [authtoken]);

  useEffect(() => {
    const params = window.location.search;

    if (params.includes('reset_token')) {
      setResetTokenLink(params);
      setActiveLoginForm(false);
      setActiveConfirmNewForm(true);
      setActiveResetPasswordForm(false);
      setActiveRegistrationForm(false);
    }
  }, [])

  return (
    <>
      {isActiveLoginForm && (
        <LoginForm
          setActiveLoginForm={setActiveLoginForm}
          setActiveResetPasswordForm={setActiveResetPasswordForm}
          setActiveRegistrationForm={setActiveRegistrationForm}
        />
      )}
      {isActiveResetPasswordForm && (
        <ResetPasswordForm
          setActiveLoginForm={setActiveLoginForm}
          setActiveResetPasswordForm={setActiveResetPasswordForm}
        />
      )}
      {isActiveConfirmNewForm && (
        <ConfirmNewPasswordForm
          resetTokenLink={resetTokenLink}
          setActiveLoginForm={setActiveLoginForm}
          setActiveConfirmNewForm={setActiveConfirmNewForm}
          setActiveResetPasswordForm={setActiveResetPasswordForm}
        />
      )}
      {isActiveRegistrationForm && (
        <RegistrationForm 
          setActiveRegistrationForm={setActiveRegistrationForm} 
          setActiveLoginForm={setActiveLoginForm}
        />
      )}
    </>
  );
};

export default Login;
