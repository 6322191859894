import React, { useState, FC, useCallback, useEffect } from "react";

import { useDispatch } from "react-redux";
import { registration } from "@/actions/account.actions";

import { Form, Input, Checkbox, Button, InputProps } from "antd";
import { Rule } from "antd/es/form";

import css from "./registration.modules.scss";
import { CheckboxChangeEvent } from "antd/es/checkbox";

const FIELDS_RULES: Record<string, Rule[]> = {
  firstName: [
    {
      pattern: /^[a-zA-Zа-яА-ЯёЁ]+$/,
      message: "Имя должно содержать только буквы",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ваше имя"));
        }

        return Promise.resolve();
      },
    },
  ],
  lastName: [
    {
      pattern: /^[a-zA-Zа-яА-ЯёЁ]+$/,
      message: "Фамилия должна содержать только буквы",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите вашу фамилию"));
        }

        return Promise.resolve();
      },
    },
  ],
  email: [
    {
      pattern: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, // sky.net-123@example-domain.com
      message: "Некорректный формат email",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ваш email"));
        }

        return Promise.resolve();
      },
    },
  ],
  companyInn: [
    {
      pattern: /^[0-9]{10,12}$/,
      message: "ИНН компании должен содержать от 10 до 12 цифр",
    },
    {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, введите ИНН компании"));
        }

        return Promise.resolve();
      },
    },
  ],
  acceptOffer: [
    {
      validator: (_, value) => {
        return value ? Promise.resolve() : Promise.reject(new Error("Вы должны принять оферту"));
      },
    },
  ],
  acceptDataProcessing: [
    {
      validator: (_, value) => {
        return value ? Promise.resolve() : Promise.reject(new Error("Вы должны принять обработку данных"));
      },
    },
  ],
  password: [
    {
      required: true,
      message: "Пожалуйста, введите пароль"
    },
    {
      pattern: /(?=\S*?[A-Z])/,
      message: "Пароль должен содержать хотя бы одну заглавную букву",
    },
    {
      pattern: /(?=\S*?[a-z])/,
      message: "Пароль должен содержать хотя бы одну строчную букву",
    },
    {
      pattern: /(?=\S*?[0-9])/,
      message: "Пароль должен содержать хотя бы одну цифру",
    },
    {
      pattern: /^[^\s]+$/,
      message: "Пароль не должен содержать пробелов",
    },
    {
      pattern: /^[a-zA-Z0-9\s]*$/,
      message: "Пароль должен содержать только латинские буквы и цифры",
    },
    {
      pattern: /.{8,}/,
      message: "Пароль должен быть длиной как минимум 8 символов",
    }
  ],
  confirmPassword: [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (!value) {
          return Promise.reject(new Error("Пожалуйста, повторите пароль"));
        }

        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }

        return Promise.reject("Пароли не совпадают");
      },
    }),
  ]
};

type FormFields = {
  firstName: string;
  lastName: string;
  email: string;
  companyInn: string;
  password: string;
  confirmPassword: string;
  acceptOffer: boolean;
  acceptDataProcessing: boolean;
};

interface IRegistrationForm {
  setActiveRegistrationForm: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveLoginForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const RegistrationForm: FC<IRegistrationForm> = ({ setActiveRegistrationForm, setActiveLoginForm }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { getFieldsError } = form;

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [companyInn, setCompanyInn] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isOfferChecked, setIsOfferChecked] = useState<boolean>(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    const hasErrors: boolean = getFieldsError().some(({ errors }) => errors.length > 0);

    setIsDisabled(
      hasErrors
      || !isOfferChecked
      || !isPolicyChecked
      || !firstName
      || !lastName
      || !email
      || !companyInn
      || !password
      || !confirmPassword
      || password !== confirmPassword
    );
  }, [
    firstName,
    lastName,
    email,
    companyInn,
    isOfferChecked,
    isPolicyChecked,
    password,
    confirmPassword,
  ]);

  const defaultInputProps: InputProps = {
    className: "flex md:w-0 md:grow-1 py-3 px-3",
    size: "large",
    style: { borderRadius: "4px" },
  };

  const closeRegistrationForm = (): void => {
    setActiveRegistrationForm(false);
    setActiveLoginForm(true);
  };

  const onClickRegistrationHandler = useCallback((): void => {
    form
      .validateFields()
      .then((values: FormFields) => {
        dispatch(registration(values));

        closeRegistrationForm();
      });
  }, [form, setActiveRegistrationForm, dispatch]);

  return (
    <div className={`${css.formWrapper} flex flex-col gap-10 mx-auto my-12 p-12`}>
      <Form name="register_form" form={form}>
        <div className="flex flex-col items-center">
          <h1 className="text-x3 mb-3">Регистрация</h1>
          <h2 className="text-n2 text-center text-blue-grey mb-3">
            Заполните ваши контактные данные и ИНН вашей компании.
          </h2>
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <Form.Item
              className="m-0 w-full"
              name="firstName"
              rules={FIELDS_RULES.firstName}
            >
              <Input {...defaultInputProps} placeholder="Имя" onChange={(e) => setFirstName(e.target.value?.trim())} />
            </Form.Item>
            <Form.Item
              className="m-0 w-full"
              name="lastName"
              rules={FIELDS_RULES.lastName}
            >
              <Input {...defaultInputProps} placeholder="Фамилия" onChange={(e) => setLastName(e.target.value?.trim())} />
            </Form.Item>
          </div>
          <div className="flex flex-row gap-2">
            <Form.Item
              className="m-0 w-full"
              name="email"
              rules={FIELDS_RULES.email}
            >
              <Input {...defaultInputProps} placeholder="Email" onChange={(e) => setEmail(e.target.value?.trim())} />
            </Form.Item>
            <Form.Item
              className="m-0 w-full"
              name="companyInn"
              rules={FIELDS_RULES.companyInn}>
              <Input {...defaultInputProps} placeholder="ИНН компании" onChange={(e) => setCompanyInn(e.target.value?.trim())} />
            </Form.Item>
          </div>
          <div className="flex flex-col gap-2">
            <Form.Item
              className="m-0 w-full"
              name="password"
              rules={FIELDS_RULES.password}
            >
              <Input.Password
                {...defaultInputProps}
                placeholder="Придумайте пароль"
                onChange={(e) => setPassword(e.target.value?.trim())}
              />
            </Form.Item>
            <Form.Item
              className="m-0 w-full"
              name="confirmPassword"
              dependencies={["password"]}
              rules={FIELDS_RULES.confirmPassword}
            >
              <Input.Password
                {...defaultInputProps}
                placeholder="Повторите пароль"
                onChange={(e) => setConfirmPassword(e.target.value?.trim())}
              />
            </Form.Item>
          </div>
          <div className="flex flex-col">
            <Form.Item
              className="m-0 w-full"
              name="acceptOffer"
              valuePropName="checked"
              rules={FIELDS_RULES.acceptOffer}
            >
              <Checkbox
                checked={isOfferChecked}
                className="text-14-r"
                onChange={(e: CheckboxChangeEvent) => setIsOfferChecked(e.target.checked)}
              >
                <p>Соглашаюсь <a href="#" className={`${css.link} m-0`}>с офертой</a></p>
              </Checkbox>
            </Form.Item>
            <Form.Item
              className="m-0 w-full"
              name="acceptDataProcessing"
              valuePropName="checked"
              rules={FIELDS_RULES.acceptDataProcessing}
            >
              <Checkbox
                checked={isPolicyChecked}
                onChange={(e: CheckboxChangeEvent) => setIsPolicyChecked(e.target.checked)}
              >
                <p>Соглашаюсь <a href="#" className={`${css.link} m-0`}>на обработку персональных данных</a></p>
              </Checkbox>
            </Form.Item>
          </div>
          <div className="flex flex-row gap-6 items-center">
            <Button
              onClick={onClickRegistrationHandler}
              className="w-full"
              type="primary"
              size="large"
              disabled={isDisabled}
            >
              Зарегистрироваться
            </Button>
          </div>
          <div className="flex flex-row gap-6 items-center">
            <p className="flex gap-1">Уже есть аккаунт?
              <a className={`${css.link} m-0 p-0`}>
                <span onClick={closeRegistrationForm}>Войдите</span>
              </a>
            </p>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationForm;